import  React from 'react'
import { Link} from 'gatsby'

const footerStyles = {
    marginTop: '70px',
    flexGrow: '1',
  }



const Footer = () => {
    return (
        <footer style={footerStyles}>


            <p>Created by <Link to='/'>Nutniti Suteerapongpan</Link></p>

            <p> </p>
        </footer>
    )
  }
  
  export default Footer