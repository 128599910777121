import React from 'react'

import Header from './header'

import Footer from './footer'

import { Helmet } from 'react-helmet'

import './layout.css'


import './styles.css'

import './theme.css'





const layoutStyles = {
    marginTop: '100px',
    flexGrow: '1',
  }

  
const sitckStyles = {
  top: "10rem"
}



const Layout = (props) => {
  
  const children = props.children
    return (

    

      
        <div className='container'>
            <Helmet>
              <meta name="google-site-verification" content="09vPqakYYFP8Ii60Sno0I2qJv1zqYg8WS_ayQeVc6AU" />

           
            
            </Helmet> 

        
            <Header />
            <div style={layoutStyles}>
            {children}
            </div>
            <Footer />
         
        </div>
    )
  }
  
  export default Layout